import * as R from 'ramda'
import {graphql} from 'gatsby'
import {Helmet} from 'react-helmet'
import React from 'react'

import {simplePagePropTypes} from 'helpers/propTypes'

import FlexDiv from 'components/UI/FlexDiv'
import Hero from 'components/UI/Hero'
import RawText from 'components/UI/RawText'
import RoundButton from 'components/UI/RoundButton'
import Section from 'components/UI/Section'
import SEO from 'components/seo'

const SimplePage = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const {countryCode, navLocale} = countryData
  const {
    callToActionLabel,
    callToActionUrl,
    content,
    imageOrVideo,
    metaTitle,
    metaDescription,
    pageTitle,
    callToActionBottomLabel,
    callToActionBottomUrl,
    callToActionBottom2Label,
    callToActionBottom2Url,
    slug,
  } = R.pathOr(' ', ['allContentfulSimplePage', 'nodes', 0], data)

  const {shortDescription} = R.pathOr(
    ' ',
    ['allContentfulSimplePage', 'nodes', 0, 'shortDescription'],
    data,
  )
  const pageId = R.pathOr(
    ' ',
    ['allContentfulSimplePage', 'nodes', 0, 'contentful_id'],
    data,
  )
  const politicaDeCalidadPdf = R.pathOr(
    ' ',
    ['politicaDeCalidadPdf', 'file', 'url'],
    data,
  )
  const politicaDeCalidadPageId = '7huSmhzFEKjqpTcEwF0UHd'
  const isPoliticaDeCalidadPage = pageId === politicaDeCalidadPageId
  const ctaSlug = R.equals(countryCode, 'worldwide')
    ? `/${countryCode}${callToActionUrl}`
    : `/${countryCode}/${navLocale}${callToActionUrl}`

  const richTextDocuments = R.pathOr(null, ['richTextDocuments', 'nodes'], data)

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  const gtagScript = `
    gtag('event', 'conversion', {'send_to': 'AW-708583495/A2LwCLPGs64YEMfA8NEC'});
  `

  const isAThankYouPage = () => {
    const regex = /thank\-you(\-lead|\-newsletter)?/

    return regex.test(slug)
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      {isAThankYouPage() && (
        <Helmet>
          <script type="text/javascript">{gtagScript}</script>
        </Helmet>
      )}
      <Section hasPaddingTop={false}>
        <Hero
          media={imageOrVideo}
          title={pageTitle}
          description={shortDescription}
          ctaTitle={callToActionLabel}
          ctaLink={isPoliticaDeCalidadPage ? politicaDeCalidadPdf : ctaSlug}
          titleType="title"
        />
        {content && (
          <RawText
            isJustified
            text={content}
            richTextDocuments={richTextDocuments}
          />
        )}
        {(callToActionBottomLabel || callToActionBottom2Label) && (
          <FlexDiv>
            {callToActionBottomLabel && (
              <RoundButton color="grey" href={callToActionBottom2Url}>
                {callToActionBottom2Label}
              </RoundButton>
            )}
            {callToActionBottom2Label && (
              <RoundButton href={callToActionBottomUrl}>
                {callToActionBottomLabel}
              </RoundButton>
            )}
          </FlexDiv>
        )}
      </Section>
    </>
  )
}

SimplePage.propTypes = simplePagePropTypes

SimplePage.defaultProps = {
  data: {
    allContentfulSimplePage: {
      nodes: {
        callToActionLabel: '',
        callToActionUrl: '',
        content: {
          raw: '',
        },
        imageOrVideo: {
          file: '',
          contentType: '',
        },
        metaDescription: {
          metaDescription: '',
        },
        metaTitle: '',
        pageTitle: '',
        shortDescription: {
          shortDescription: '',
        },
      },
    },
  },
  pageContext: {},
}

export default SimplePage

export const pageQuery = graphql`
  query templateSimplePage(
    $entityRegEx: String
    $nodeLocale: String
    $contentId: String
    $technicalName: String
  ) {
    politicaDeCalidadPdf: contentfulAsset(
      contentful_id: {eq: "1nz0Q2MOxj8PQsEQeSEZu9"}
    ) {
      file {
        url
      }
    }
    allContentfulSimplePage(
      filter: {
        node_locale: {eq: $nodeLocale}
        contentful_id: {eq: $contentId}
        slug: {regex: $entityRegEx}
      }
    ) {
      nodes {
        contentful_id
        callToActionLabel
        callToActionUrl
        callToActionBottomLabel
        callToActionBottomUrl
        callToActionBottom2Label
        callToActionBottom2Url
        content {
          raw
          references {
            ... on ContentfulContactUs {
              contentful_id
              name
              slug
            }
            ... on ContentfulDownloadPage {
              contentful_id
              name
              slug
            }
            ... on ContentfulSimplePage {
              contentful_id
              slug
              name
            }
            ... on ContentfulAsset {
              description
              contentful_id
              file {
                url
                contentType
              }
              __typename
            }
            ... on ContentfulEntry {
              contentful_id
              __typename
              ... on ContentfulDocument {
                name
              }
            }
          }
        }
        imageOrVideo {
          file {
            contentType
            url
          }
        }
        metaDescription {
          metaDescription
        }
        metaTitle
        pageTitle
        slug
        shortDescription {
          shortDescription
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    richTextDocuments: allContentfulDocument(
      filter: {node_locale: {eq: "en-US"}}
    ) {
      nodes {
        contentful_id
        name
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
  }
`
